<template>
  <div class="annotations-side-bar">
    <div class="annotations-header">
      <h5>Annotations</h5>
    </div>
    <div class="annotations-body">
      <ul>
        <li v-for="(annotation, i) in annotations" :key="i" class="annotation-row"
          :class="{ 'selected': i === selectedAnnotationIndex }" @click="selectAnnotation(i)">
          <h5 class="annotation-row-header">{{ i + 1 }} - {{ annotation.content }}</h5>
          <div class="annotation-row-body">
            <p class="annotation-row-page">Highlight • Page {{ annotation.page }}</p>
            <p class="annotation-row-content">Content: {{ annotation.content }}</p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import { defineEmits, defineProps, onMounted, ref } from 'vue'

defineProps({
  annotations: {
    type: Array,
    required: true,
  },
})

const emit = defineEmits(['scrollTo'])

const selectedAnnotationIndex = ref(0)

const selectAnnotation = (index) => {
  selectedAnnotationIndex.value = index
  emit('scrollTo', index)
}

onMounted(() => {
  emit('scrollTo', selectedAnnotationIndex.value)
})
</script>

<style lang="scss" scoped>
.annotations-side-bar {
  position: sticky;
  align-self: start;
  grid-column: 2 / 3;
  top: 0;
  left: 0;
  background: var(--White);
  height: calc(100vh - 70px);
  width: 100%;
  overflow-y: auto;
  border-left: 1px solid var(--Grey200);


  .annotations-header {
    border-bottom: 1px solid var(--Grey200);
  }
}

.annotation-row {
  --base-font-size: 0.4rem;

  cursor: pointer;
  list-style: none;
  text-align: start;
  border-bottom: 1px solid var(--Grey200);

  &:hover,
  &.selected {
    --base-font-size: 0.42rem;
  }

  &.selected {
    box-shadow: 3px 3px 10px var(--Grey800);
    border-left: 4px solid var(--MarketingOrange);
    padding-left: 0.5rem;
    padding-top: 0.5rem;
    transition: background 0.3s ease, box-shadow 0.3s ease, border-left 0.3s ease;
  }

  /* Need to apply transitions directly because calc is being used. */
  .annotation-row-header,
  .annotation-row-body .annotation-row-page,
  .annotation-row-body .annotation-row-content {
    transition: font-size 500ms ease-in-out;
  }

  .annotation-row-header {
    margin-top: 1rem;
    font-size: calc(var(--base-font-size) * 2);
  }

  .annotation-row-body {
    padding: 0 0 1rem 2rem;

    .annotation-row-page,
    .annotation-row-content {
      margin: 0;
    }

    .annotation-row-page {
      color: var(--Grey500);
      font-size: calc(var(--base-font-size) * 1.75);
    }

    .annotation-row-content {
      font-size: calc(var(--base-font-size) * 2);
    }
  }
}

.annotations-header {
  font-size: 1.25rem;

  h5 {
    margin-bottom: 1.25rem;
  }
}

.annotations-body {
  ul {
    list-style: none;
    padding: 0 1rem;
  }
}
</style>

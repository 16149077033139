// Copyright (C) dātma, inc™ - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import { watch } from 'vue'
import Shepherd from 'shepherd.js'

import router from '@/router'
import { noBackend } from '@/settings'

export const tutorial = new Shepherd.Tour({
  useModalOverlay: true,
  exitOnEsc: true,
})

export const tutorialNext = () => {
  if (!tutorial.steps || tutorial.steps.length === 0) { return }
  tutorial.next()
}

export const setupTutorial = (routeToDashboard, store, languageProxy) => {
  const startTutorial = () => {
    if (tutorial.steps && tutorial.steps.length !== 0) { return }

    tutorial.steps = []
    const cancelTutorial = () => {
      tutorial.complete()
      tutorial.steps = []
      tutorial.off('cancel')
    }
    tutorial.on('cancel', cancelTutorial)

    watch(languageProxy, () => {
      const tr = store.getters.translate
      for (let i = 0; i < tutorial.steps.length; i++) {
        const title = tr(`tutorial-step-${i + 1}-title`)
        const text = tr(`tutorial-step-${i + 1}`)
        // Translate existing instance of the tutorial:
        const stepTitle = document.querySelector(`#step-${i + 1}-label`)
        if (stepTitle) { stepTitle.innerHTML = title }
        const stepText = document.querySelector(`#step-${i + 1}-description`)
        if (stepText) { stepText.innerHTML = text }
        // Translate all future instances of the tutorial:
        tutorial.steps[i].options.title = title
        tutorial.steps[i].options.text = text
      }
    })

    const tr = store.getters.translate
    const allSteps = [{
      id: 'step-1',
      title: tr('tutorial-step-1-title'),
      text: tr('tutorial-step-1'),
      attachTo: {
        element: '.form-control',
        on: 'left',
      },
      buttons: [
        {
          action() {
            return this.next()
          }, text: 'Next',
        },
      ],
      arrow: false,
    },
    {
      id: 'step-2',
      title: tr('tutorial-step-2-title'),
      text: tr('tutorial-step-2'),
      attachTo: {
        element: '#tutorial-profile-step-target',
        on: 'left',
      },
      buttons: [
        {
          action() {
            return this.back()
          },
          classes: 'shepherd-button-secondary',
          text: 'Back',
        },
        {
          action() {
            return this.next()
          }, text: 'Next',
        },
      ],
      arrow: false,
    },
    {
      id: 'step-3',
      title: tr('tutorial-step-3-title'),
      text: tr('tutorial-step-3'),
      attachTo: {
        element: '.toggle-theme',
        on: 'bottom',
      },
      buttons: [
        {
          action() {
            return this.back()
          },
          classes: 'shepherd-button-secondary',
          text: 'Back',
        },
        {
          action() {
            router.push(routeToDashboard)
            return this.next()
          }, text: 'Next',
        },
      ],
    },
    {
      id: 'step-4',
      title: tr('tutorial-step-4-title'),
      text: tr('tutorial-step-4'),
      buttons: [
        {
          action() {
            return this.next()
          }, text: 'Next',
        },
      ],
      arrow: false,
    },
    {
      id: 'step-5',
      title: tr('tutorial-step-5-title'),
      text: tr('tutorial-step-5'),
      attachTo: {
        element: '.create-new-session-button',
        on: 'right-start',
      },
    },
    {
      id: 'step-6',
      title: tr('tutorial-step-6-title'),
      text: tr('tutorial-step-6'),
      attachTo: {
        element: 'li',
        on: 'left-start',
      },
    },
    {
      id: 'step-7',
      title: tr('tutorial-step-7-title'),
      text: tr('tutorial-step-7'),
      attachTo: {
        element: '.add-tools',
        on: 'bottom',
      },
    },
    {
      id: 'step-8',
      title: tr('tutorial-step-8-title'),
      text: tr('tutorial-step-8'),
      attachTo: {
        element: '.dialog',
        on: 'left-start',
      },
      advanceOn: {
        selector: '.add-button',
        event: 'click',
      },
      arrow: false,
    },
    {
      id: 'step-9',
      title: tr('tutorial-step-9-title'),
      text: tr('tutorial-step-9'),
      attachTo: {
        element: '.patient-info',
        on: 'bottom',
      },
      advanceOn: {
        selector: '.patient-info',
        event: 'click',
      },
    },
    {
      id: 'step-10',
      title: tr('tutorial-step-10-title'),
      text: tr('tutorial-step-10'),
      attachTo: {
        element: '.dialog',
        on: 'left-start',
      },
      arrow: false,
    },
    {
      id: 'step-11',
      title: tr('tutorial-step-11-title'),
      text: tr('tutorial-step-11'),
      attachTo: {
        element: '.dialog',
        on: 'right-start',
      },
      arrow: false,
    },
    {
      id: 'step-12',
      title: tr('tutorial-step-12-title'),
      text: tr('tutorial-step-12'),
      buttons: [
        {
          action() {
            tutorial.off('cancel')
            this.complete()
            this.steps = []
          }, text: 'OK',
        },
      ],
      arrow: false,
    },
    ]

    const noBackendSteps = [
      allSteps[0],
      allSteps[1],
      {
        id: 'step-3',
        title: tr('tutorial-step-3-title'),
        text: tr('tutorial-step-3'),
        attachTo: {
          element: '.toggle-theme',
          on: 'bottom',
        },
        buttons: [
          {
            action() {
              return this.back()
            },
            classes: 'shepherd-button-secondary',
            text: 'Back',
          },
          {
            action() {
              router.push(store.getters.mostRecentSessionUrlOrDashboard)
              return this.next()
            }, text: 'Next',
          },
        ],
      },
      {
        id: 'step-3',
        title: tr('tutorial-step-3-title'),
        text: tr('tutorial-step-3'),
        attachTo: {
          element: '.toggle-theme',
          on: 'bottom',
        },
        buttons: [
          {
            action() {
              return this.back()
            },
            classes: 'shepherd-button-secondary',
            text: 'Back',
          },
          {
            action() {
              return this.next()
            }, text: 'Next',
          },
        ],
      },
      allSteps[6],
      allSteps[7],
      allSteps[8],
      allSteps[9],
      allSteps[10],
      allSteps[11],
    ]

    const finalSteps = noBackend ? noBackendSteps : allSteps

    tutorial.addSteps(finalSteps)

    tutorial.start()
  }

  return startTutorial
}

export default {
  install: (app) => {
    app.provide('tutorialNext', tutorialNext)
    app.provide('setupTutorial', setupTutorial)
  },
}

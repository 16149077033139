// Copyright (C) dātma, inc™ - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { store } from './store'
import { bitbucketBuildNumber, bitbucketCommit, githubSHA, logoutAfterInactivityMinutes, noBackend, providenceBuild, pubsubHost, pubsubPort } from './settings'
import { VERSION } from './version.js'

import pubsub from '@/plugins/v1/pubsub/pubsub.js'
import translation from '@/plugins/translation.js'
import tutorial from '@/plugins/tutorial/tutorial.js'
import TheHeader from '@/components/nav/TheHeader.vue'
import ToggleThemeIcon from '@/components/ui/icons/ToggleThemeIcon.vue'
import { search } from '@/components/ui/FindPatientModal/search.js'
import PrimeVue from 'primevue/config'
import ToastService from 'primevue/toastservice'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Listbox from 'primevue/listbox'
import Calendar from 'primevue/calendar'

console.log(`VERSION=${VERSION}`)
console.log(`GITHUB_SHA=${githubSHA}`)
console.log(`VUE_APP_BITBUCKET_BUILD_NUMBER=${bitbucketBuildNumber}`)
console.log(`VUE_APP_BITBUCKET_COMMIT=${bitbucketCommit}`)
console.log(`VUE_APP_PUBSUB_HOST=${pubsubHost}`)
console.log(`VUE_APP_PUBSUB_PORT=${pubsubPort}`)
console.log(`VUE_APP_NO_BACKEND=${noBackend}`)
console.log(`VUE_APP_PROVIDENCE_BUILD=${providenceBuild}`)
console.log(`VUE_APP_LOGOUT_AFTER_INACTIVITY_MINUTES=${logoutAfterInactivityMinutes}`)

// All black box tools can watch for changes to window.parent.ux2 to get
// dynamic updates when theme colors or language changes are made.
const ux2Handler = {
  get: function (obj, key) {
    const result = Reflect.get(obj, key)
    if (typeof result === 'function')
      return result.bind(obj)
    return result
  },
  set: (obj, key, value) => {
    Reflect.set(obj, key, value)
    obj.dispatchEvent(new CustomEvent('change', { detail: { key, value } }))
    return true
  },
}
window.ux2 = new Proxy(new EventTarget(), ux2Handler)
window.ux2.addOrUpdateTool = async (toolName, toolTitle, payload, toolId, width, height, row, col) => {
  return await store.dispatch('addOrUpdateTool', { toolName, toolTitle, payload, toolId, width, height, row, col })
}
window.ux2.getProfile = () => store.getters.profile
window.ux2.setSessionPatient = async (query) => {
  const results = await search(query)
  if (!results || results.length < 1) { return '' }
  return await store.dispatch('setSessionPatient', { patient: results[0], publish: true })
}

createApp(App)
  .use(store)
  .use(router)
  .use(pubsub)
  .use(translation)
  .use(tutorial)
  .use(PrimeVue)
  .use(ToastService)
  .component('the-header', TheHeader)
  .component('toggle-theme-icon', ToggleThemeIcon)
  .component('DataTable', DataTable)
  .component('Listbox', Listbox)
  .component('Column', Column)
  .component('Calendar', Calendar)
  .mount('#app')

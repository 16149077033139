// Copyright (C) dātma, inc™ - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import { connect } from 'emitter-io'

import { presenceChannelKey, pubsubHost, pubsubPort } from '@/settings'

export const withBackendSetup = (app) => {
  const host = pubsubHost
  const port = pubsubPort
  const secure = port === '443'

  const client = connect({ host, port, secure })

  // channel: v1/presence/#/ (Read and Presence permissions)
  // No need to unsubscribe - when the connection dies, this client will
  // be unsubscribed.
  client.subscribe({
    key: presenceChannelKey,
    channel: `v1/presence/`,
  })

  const maxPayloadBytes = 64000  // experimental maximum: 65394
  const bufferedPublish = (key, channel, message) => {
    const parts = Math.floor(message.length / maxPayloadBytes) + (message.length % maxPayloadBytes > 0 ? 1 : 0)
    if (message.length === 0 || parts === 1) {
      return client.publish({ key, me: false, channel, message })
    }

    let partNum = 1
    while (message.length > 0) {
      let msg = message
      if (msg.length > maxPayloadBytes) {
        msg = msg.substring(0, maxPayloadBytes)
        message = message.substring(maxPayloadBytes)
      } else {
        message = ''
      }

      const tmpChannel = `${channel}/part-${partNum}-of-${parts}`
      client.publish({ key, me: false, channel: tmpChannel, message: msg })
      partNum++
    }
  }

  const publishToKey = (key) => {
    return ({ channel, message }) => {
      if (!channel || channel.includes('//') || channel.includes('undefined')) {
        console.log(`ERROR: publishToKey: bad channel: ${channel}`)
        return
      }
      if (!channel.includes('/ydoc/')) {
        console.log(`pubsub publish("${channel}"): ${message.length} bytes`)
      }
      // if (channel.endsWith('/tools')) { console.log(message) }  // useful for creating new session template layouts.
      bufferedPublish(key, channel, message)
    }
  }
  app.provide('publishToKey', publishToKey)

  client.on('connect', () => { console.log('pubsub on connect') })
  client.on('disconnect', () => { console.log('pubsub on disconnect') })
  client.on('offline', () => { console.log('pubsub on offline') })
  client.on('error', (error) => { console.log('pubsub UNHANDLED error:', error) })
  client.on('presence', (obj) => { console.log('pubsub UNHANDLED presence:', obj) })
  client.on('me', (obj) => { console.log('pubsub UNHANDLED me:', obj) })

  return { client, publishToKey }
}

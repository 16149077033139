// Copyright (C) dātma, inc™ - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
import { upennBuild } from '@/settings.js'

import { defaultProvidenceSessionTools, initToolsProvidencePayload } from './initToolsProvidence.js'
import { defaultUPennSessionTools, initToolsUPennPayload } from './initToolsUPenn.js'

export const initToolsPayload = upennBuild ? initToolsUPennPayload : initToolsProvidencePayload

export const defaultSessionTools = upennBuild ? defaultUPennSessionTools : defaultProvidenceSessionTools

// Copyright (C) dātma, inc™ - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import { createStore } from 'vuex'

import { actions } from './actions.js'
import { defaultFeatureToggles } from './featureToggles.js'
import { getters } from './getters.js'
import { mutations } from './mutations.js'

export const updateActivityTracker = () => {
  localStorage.setItem('lastActivitySecondsSinceEpoch', Math.round((new Date().getTime()) / 1000))
}

const state = () => {
  const allAvailableTools = {}
  const allUsers = []
  const annotations = []
  const authResponse = {}
  const featureToggles = defaultFeatureToggles
  const isFindPatientModalOpen = false
  const isFindPhysicianModalOpen = false
  const profile = {}
  const reports = []
  const reportTypes = {}
  const selectedPathologyObject = {}
  const session = {}
  const sessionTemplates = []
  const showSystemToast = ''
  const showToastType = ''

  return {
    allAvailableTools,
    allUsers,
    annotations,
    authResponse,
    featureToggles,
    isFindPatientModalOpen,
    isFindPhysicianModalOpen,
    profile,
    reports,
    reportTypes,
    selectedPathologyObject,
    session,
    sessionTemplates,
    showSystemToast,
    showToastType,
  }
}

// exported for testing purposes.
export const testStore = {
  state,
  mutations,
  actions,
  getters,
}

export const store = createStore(testStore)

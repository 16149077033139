/*
* from Keycloak
* https://github.com/keycloak/keycloak
*
 * Copyright 2016 Red Hat, Inc. and/or its affiliates
 * and other contributors as indicated by the @author tags.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

export const check3pCookiesSupported = async (iframeSrc, messageTimeout = 10000) => {
  let isSupported = false

  const iframe = document.createElement('iframe')
  iframe.setAttribute('src', iframeSrc)
  iframe.setAttribute('sandbox', 'allow-storage-access-by-user-activation allow-scripts allow-same-origin')
  iframe.setAttribute('title', 'keycloak-3p-check-iframe')
  iframe.style.display = 'none'
  document.body.appendChild(iframe)

  try {
    await new Promise((resolve, reject) => {
      const messageCallback = (event) => {
        if (iframe.contentWindow !== event.source) { return }
        if (event.data !== 'supported' && event.data !== 'unsupported') { return }

        isSupported = event.data === 'supported'

        if (!isSupported) {
          console.warn(
            'Your browser is blocking access to 3rd-party cookies, this means:\n\n' +
            ' - It is not possible to automatically detect changes to the session status (such as the user logging out in another tab).',
          )
        }

        window.removeEventListener('message', messageCallback)
        resolve()
      }

      window.addEventListener('message', messageCallback, false)

      setTimeout(() => {
        window.removeEventListener('message', messageCallback)
        reject(new Error('Timeout when waiting for 3rd party check iframe message.'))
      }, messageTimeout)
    })
  } finally {
    document.body.removeChild(iframe)
  }

  return isSupported
}

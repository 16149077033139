<template>
  <div class="pdf-section" :class="{ 'sidebar-open': annotationsSideBarVisible }">
    <vue-pdf-embed ref="pdfRef" :style="{ visibility: doneLoading ? 'visible' : 'hidden' }"
      :text-layer="!!annotations.length" :annotation-layer="!!annotations.length" :source="annotatedPdf"
      @rendered="handleDocumentRender" />
    <AnnotationsSidebar v-if="annotationsSideBarVisible" :annotations="annotations" @scrollTo="scrollToAnnotation" />
  </div>
</template>

<script setup>
import { defineEmits, defineProps, ref } from 'vue'
import VuePdfEmbed from 'vue-pdf-embed'
import AnnotationsSidebar from '@/ux3/cards/ReportViewer/AnnotationsSidebar.vue'

const props = defineProps({
  annotations: Array,
  annotationsSideBarVisible: Boolean,
  annotatedPdf: [String, Array],
  doneLoading: Boolean,
})

const emit = defineEmits(['rendered', 'updateSidebar'])

const pdfRef = ref(null)
const selectedAnnotationIndex = ref(0)
const annotationElements = ref([])
const currentLinkIndex = ref(0)

const handleDocumentRender = () => {
  // If there are annotations, find elements on the text layer, add them to the state
  // variable, and scroll to the first one.
  if (props.annotations && props.annotations.length) {
    emit('updateSidebar', true)
    annotationElements.value = pdfRef.value?.$el?.querySelectorAll('.highlightAnnotation') || []
    if (annotationElements.value.length) {
      scrollToAnnotation(currentLinkIndex.value)
    }
  }

  emit('rendered')
}

const scrollToAnnotation = (index) => {
  selectedAnnotationIndex.value = index
  const annotation = annotationElements.value[index]
  if (annotation) {
    annotation.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    })
  }
}
</script>

<style lang="scss" scoped>
.pdf-section {
  display: grid;
  grid-template-columns: 1fr;
  padding-left: 3.5rem;

  &.sidebar-open {
    grid-template-columns: min-content auto;

    .vue-pdf-embed {
      width: 100%;
    }
  }

  .vue-pdf-embed {
    position: relative;
    width: 100%;
    max-width: 70vw;
    height: 100%;
    margin: auto;
  }
}
</style>

<template>
  <button class="federation-toggle" @click.prevent="toggleFederation" :style="`color:${svgStrokeColor}`"><svg
      viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.40911 13.9014C10.3965 13.9014 12.8182 11.4276 12.8182 8.37598C12.8182 5.32439 10.3965 2.85059 7.40911 2.85059C4.42174 2.85059 2 5.32439 2 8.37598C2 11.4276 4.42174 13.9014 7.40911 13.9014Z"
        :stroke="svgStrokeColor" stroke-width="3" stroke-miterlimit="10" />
      <path
        d="M7.40911 36C10.3965 36 12.8182 33.5262 12.8182 30.4746C12.8182 27.423 10.3965 24.9492 7.40911 24.9492C4.42174 24.9492 2 27.423 2 30.4746C2 33.5262 4.42174 36 7.40911 36Z"
        :stroke="svgStrokeColor" stroke-width="3" stroke-miterlimit="10" />
      <path
        d="M29.8749 36C32.8623 36 35.284 33.5262 35.284 30.4746C35.284 27.423 32.8623 24.9492 29.8749 24.9492C26.8876 24.9492 24.4658 27.423 24.4658 30.4746C24.4658 33.5262 26.8876 36 29.8749 36Z"
        :stroke="svgStrokeColor" stroke-width="3" stroke-miterlimit="10" />
      <path
        d="M30.7089 13.0508C33.6963 13.0508 36.118 10.577 36.118 7.52539C36.118 4.4738 33.6963 2 30.7089 2C27.7215 2 25.2998 4.4738 25.2998 7.52539C25.2998 10.577 27.7215 13.0508 30.7089 13.0508Z"
        :stroke="svgStrokeColor" stroke-width="3" stroke-miterlimit="10" />
      <path
        d="M29.8764 24.9491V12.9757C27.4211 12.5862 25.5144 10.5154 25.3203 7.94922H12.7961C12.8061 8.08932 12.8162 8.22942 12.8162 8.37293C12.8162 11.2809 10.6151 13.6591 7.8252 13.8778V24.9696C10.6184 25.1883 12.8162 27.5666 12.8162 30.4745H24.4673C24.4673 27.4231 26.8892 24.9491 29.8764 24.9491Z"
        :stroke="svgStrokeColor" stroke-width="3" stroke-miterlimit="10" />
      <path
        d="M18.8495 24.7373C21.8369 24.7373 24.2587 22.2635 24.2587 19.2119C24.2587 16.1603 21.8369 13.6865 18.8495 13.6865C15.8622 13.6865 13.4404 16.1603 13.4404 19.2119C13.4404 22.2635 15.8622 24.7373 18.8495 24.7373Z"
        :stroke="svgStrokeColor" stroke-width="3" stroke-miterlimit="10" />
      <path d="M22.6709 23.1133L26.1331 26.6499" :stroke="svgStrokeColor" stroke-width="3" stroke-miterlimit="10" />
      <path d="M11.5693 11.7764L14.9747 15.2549" :stroke="svgStrokeColor" stroke-width="3" stroke-miterlimit="10" />
      <path d="M15.0583 23.1787L11.5693 26.6504" :stroke="svgStrokeColor" stroke-width="3" stroke-miterlimit="10" />
      <path d="M26.9639 11.3496L22.8359 15.4501" :stroke="svgStrokeColor" stroke-width="3" stroke-miterlimit="10" />
    </svg>
    datma.FED</button>
</template>

<script setup>
// Copyright (C) dātma, inc™ - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
import { computed } from 'vue'
import { useStore } from 'vuex'

const store = useStore()

const toggleFederation = () => store.dispatch('setFederation', !store.getters.federation)

const svgStrokeColor = computed(() => !store.getters.isLightMode || store.getters.currentThemeName === 'dātma light' ? '#fff' : '#100f0d')
</script>

<style lang="scss" scoped>
.federation-toggle {
  display: flex;
  align-items: center;
  gap: 0.3rem;
  background: none;
  font: inherit;
  font-size: 1rem;
  margin: 0;
  border: none;
  cursor: pointer;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

svg {
  width: 2rem;
}
</style>
